@media screen and (min-width:1536px) {}

@media screen and (min-width:1200px) and (max-width:1535px) {}

@media screen and (min-width:900px) and (max-width:1199px) {}

@media screen and (min-width:600px) and (max-width:899px) {
    .client-about{
        min-width: auto;    
    }
    
    .set-web .MuiFormControl-fullWidth {
    width: 50%;
    padding: 5px;
   }

    .email-small-btn3 {
        position: absolute;
        right: 9px !important;
      }
    .coinlogs-ul li {
        padding: 5px 10px;
    }
    .mob-menu-left button {
        padding: 0px;
    }

    .icolab-tabel tr td,
    .icolab-tabel tr th {
        padding-left: 15px;
    }

    .icolab-tabel tr th:nth-child(2) {
        width: 186px;
    }

    .icolab-tabel tr th:nth-child(1) {
        width: 209px;
        padding-left: 16px;
    }

    .tabel-box .icolab-tabel {
        min-width: 860px;

    }

    .remember-box {
        margin-left: 0px;
    }

    .ico-section2 {
        /* background-image: none; */
        height: 450px;
        /* min-height: 460px; */
        min-height: 500px;
    }

    /* / contact page start / */
    .contact-bg {
        height: 400px !important;
    }

    .big-text-contact {
        font-size: 30px;
        line-height: 45px;
        margin: 0 0 15px 0 !important;
    }

    .small-text-contact {
        font-size: 18px;
        line-height: 35px;
    }

    .spaces-contactus {
        padding: 50px 0;
    }

    .ipade-flex {
        display: flex;
    }

    .social-part {
        margin-left: 60px;
    }

    .msg {
        margin-bottom: 50px;
    }

    .name-mail {
        margin-bottom: 30px !important;
        justify-content: space-evenly;
    }

    .sub .subject-box .MuiInputBase-formControl {
        width: 100% !important;
    }

    .msg textarea {
        width: 100%;
    }

    .sub {
        margin-bottom: 30px !important;
    }


    /* / contact page end / */
    /* / register page start / */

    .login-inner-text p {
        font-size: 25px;
        margin-bottom: 25px;
    }

    .connect-title p {
        font-size: 15px;
        margin-bottom: 5px;
    }

    .register-input .input-register .MuiInputBase-formControl {
        width: 305px !important;
        height: 45px;
    }

    .inline-checkbox span {
        font-size: 15px;
    }

    .signup-text p {
        font-size: 15px;
    }

    .register-btn button {
        margin-bottom: 21px;
        margin-top: 27px;
        width: 360px !important;
    }

    /* / register page end / */
    /* login page */
    .login-main-box {
        display: flex;
    }

    /* about page */
    .about-text-inner p {
        font-size: 17px;
        line-height: 32px;
    }

    .about-third-phase-section {
        height: 250px !important;
        min-height: 250px !important;
    }

    .about-text p {
        font-size: 30px;
        line-height: 33px;
        padding-bottom: 0px;
    }

    .blockchain-sec {
        padding: 45px 15px 45px 15px;
    }

    .blockchain-main-img-box img {
        width: 300px;
        height: 300px;
        object-fit: cover;
        display: flex;
        margin-top: 28px;
    }

    .blockchain-section {
        min-height: 470px !important;
    }

    .blockchain-text-inner p {
        padding: 10px 0px 10px 0px !important;
    }

    /* home page */
    .res-login-btn.css-1pz7awu-MuiButtonBase-root-MuiIconButton-root {
        padding: 5px !important;
    }

    .mob-menu-img {
        display: block;
    }

    .icolab-features p {
        font-size: 30px;
        line-height: 37px;
    }

    .email-small-btn {
        position: absolute;
        right: 55px;
    }

    .footer-logo {
        height: 110px !important;
        width: 110px !important;
    }

    .email-img-big {
        min-height: 290px;
        height: 311px;
    }

    /* .css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
        box-shadow: none !important;
        min-width: 100px !important;
        width: 100%;
    } */
    .sub-text {
        font-size: 30px !important;
        line-height: 35px !important;
    }

    .frequently-section {
        /* padding: 0px 15px; */
        min-height: 740px;
    }

    .ico-calender p {
        font-size: 30px;
        line-height: 37px;
    }

    .pb-73 {
        padding-bottom: 30px;
    }

    .dot-box {
        height: 15px;
        width: 15px;
    }

    .dot-text p {
        font-size: 18px;
        line-height: 27px;
    }

    .text-box-inner p {
        font-size: 13px;
        line-height: 24px;
    }

    .client-section-box {
        padding: 53px 0px 53px 0px;
    }

    .main-titel {
        font-size: 30px !important;
        line-height: 36px !important;
    }

    /* .client-section {
      padding: 0px 15px;
    } */
    .mt-111 {
        margin-top: -21px;
    }

    .openicone-img {
        height: 40px;
        width: 60px;
    }

    .openicone-text {
        font-size: 15px !important;
        line-height: 17px !important;
    }

    .openicone-inner-text {
        /* padding-top: 23px; */
        padding-top: 120px;
    }
    .openicone {
        position: absolute;
        left: 11px;
        top: 140px;
    }
    .about-name {
        font-size: 21px !important;
        line-height: 23px !important;
        padding: 10px 0px 10px 0px;
    }

    .about-position {
        font-size: 15px !important;
        line-height: 25px !important;
    }

    .client-inner-text {
        padding-bottom: 40px;
    }
    .res-ipad-team-set{
        margin: 63px 0px 0px 0px !important;
    }
    .banner-content .es-banner-title {
        
        font-size: 35px;
        line-height: 40px;
     margin-bottom: 0px; 
        
    }
    .icolab-tabel tr td,
    .icolab-tabel tr th {
        padding-top: 18px;
        padding-bottom: 18px;
    }

    .icolab-tabel tr th {
        font-size: 20px;
        line-height: 23px;
    }

    .icolab-tabel tr td {
        font-size: 10px;
        line-height: 24px;
    }

    .ico-calender-section {
        min-height: 573px;
        /* padding: 0px 15px; */
        height: auto;
    }

    .icolab-icone-img {
        width: 25px;
        height: 15px;
    }

    .icolab-features-text p {
        font-size: 17px;
        line-height: 22px;
    }

    .icolab-features-inner-text p {
        padding: 10px 15px 15px 15px;
        line-height: 25px;
    }

    .icolab-features-text p {
        font-size: 17px;
        line-height: 22px;
    }

    .icolab-features-inner-text p {
        padding: 10px 15px 15px 15px;
        line-height: 20px;
        font-size: 14px;
    }

    .icolab-features-card-box {
        padding: 44px 0px 50px 0px;
    }

    .icolab-features-section {
        height: auto;
        min-height: 445px;

    }

    .meet-our-team-section {
        height: 275px;
    }

    .icolab-icone {
        padding: 10.54px 0px 10.54px 0px;
    }

    .roadmap-section {
        min-height: 1060px;
        /* padding: 0px 15px; */
    }

    .roadmap-main-section {
        padding: 50px 0px 50.34px 0px;
    }

    .ico-main-img-box img {
        width: 365px;
        height: 365px;
        object-fit: cover;
    }

    .ico-sec {
        padding: 50px 15px 50px 0px;
    }

    .ico-solution p {
        font-size: 27px;
        line-height: 30px;
    }

    .ico-solution-inner p {
        /* padding: 30px 0px 45px 0px;
        font-size: 15px;
        line-height: 27px; */
        padding: 0px 0px 10px 0px;
        font-size: 13px;
        line-height: 25px;
    }

    .ico-section {
        height: auto;
        min-height: 460px;
        background-image: none;
    }

    /* .ico-solution-box {
        margin-left: 50px;

    } */
    .third-phase-section {
        min-height: 660px;
        height: auto;
    }

    .up-coming-main-box {
        min-width: 332px;
        height: 417px;
        padding: 44px 32px 67px 15px;
        width: 100%;
    }

    .team-img {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
    }

    /* .meet-box .mr-30 {
        margin-bottom: 20px;
    } */
    .meet-img-inner {
        height: auto;
        max-width: 255px;
        margin-left: -30px;
    }

    .meet-our-team-section-img {
        height: auto;
    }

    .third-phase-section {
        min-height: 463px;
        height: 463px;
        /* padding: 0px 15px; */
    }

    .third-phase-sec {
        padding: 50px 0px 50px 0px;
    }

    .website p {
        font-size: 25px;
        line-height: 35px;
    }

    .up-coming-main-text p {
        font-size: 24px;
        line-height: 30px;
    }

    .up-coming-main-text-inner p {
        font-size: 15px;
        line-height: 22px;
    }

    .clender-box {
        width: 70px;
        height: 70px;
    }

    .clender-box-inner p {
        font-size: 20px;
        line-height: 24;
    }

    .website-inner-text p {
        line-height: 30px;
        font-size: 14px;
    }

    .usd-text p {
        font-size: 23px;
        line-height: 35px;
    }

    .price-text p {
        font-size: 15px;
        line-height: 35px;
    }

    .day-main-box {
        padding: 30px 0px 29px 0px;
    }

    .res-pt-30 {
        padding-top: 30px;
    }

    .header.css-1f3i64k {
        width: 608px;
        /* margin-right: 20px; */
        display: none;

    }

    .mob-menu-set .MuiPaper-elevation {
        height: 100vh !important;
        width: 260px;
        max-height: 100%;
    }

    .header li {
        font-size: 17px;
    }

    .header a {
        font-size: 15px;
        line-height: 35px;
        margin-right: 6px;
    }

    .header-btn-login .button,
    .header-btn-login .button:hover {
        min-width: 100px !important;
        width: 100%;
    }

    .logo {
        padding: 0px 0px 0px 0px;
    }

    .css-1pz7awu-MuiButtonBase-root-MuiIconButton-root {
        padding: 0px !important;
    }

    .meet-box .meet-cards:nth-child(even) {
        margin-right: 0;
    }

    .meet-box .meet-cards {
        /* margin-right: 30px; */
        margin-bottom: 20px;
        margin-right: 0px;
    }
    .team-text-box {
        display: flex;
        justify-content: center;
    }
    .text {
        justify-content: center;
        display: flex;
    }
    .mob-menu {
        display: none;
    }

    .mob-menu-left button svg {
        display: none;
    }
    .coinlogs-section {
        min-height: 349px;
    }
    .register-input2 .input-register .MuiInputBase-formControl {
        width: 100% !important;
        min-width: 359px !important;
    }
    .squre-box2 {
        width: 211px;
        min-height: 95px;
    }
    .roadmap-border2 {
        height: 80px;
        width: 80px;
        left: 91%;
    }
    .dot-box2 {
        height: 70px;
        width: 70px;
    }
    .squre-box-set p {
        padding-top: 30px;
    }
    .counter-text p {
        font-size: 19px;
    }
    .userdashboard-tabel tr th:nth-child(1) {
        width: 127px !important;
        padding-left: 30px;
    }
    .profile-set {
        margin: 4px 21px 0px;
    }
    .textfield-set2 {
        /* height: 64px; */
        width: 100%;
        max-width: 95% !important;
        background-color: white;
    }
    .counter-text2 p {
        font-size: 17px;
    }
    .email-small-btn2 {
        position: absolute;
        right: 16px !important;
    }
    .email-box3 {
        width: 359px!important;
    }
    .email-small-btn3 {
        position: absolute;
        right: 0px !important;
    }

}

@media screen and (min-width:0px) and (max-width:599px) {
    
    .add-copy-link-main2 {
        display: block;
        align-items: center;
    }
    .email-box3 {
        width: 100%!important;
    }
    .email-box4 {
        width: 100%!important;
        margin: 15px 0px;
    }
    .set-copy-link {
        padding: 40px 0px 0px;
    }
    .subscribe-btn2 button {
        background: #87C443 0% 0% no-repeat padding-box !important;
        /* border: 1px solid #F9C11478 !important; */
        min-width: 80px !important;
    }
    .subscribe-btn3 button {
        background: #87C443 0% 0% no-repeat padding-box !important;
        /* border: 1px solid #F9C11478 !important; */
        min-width: 116px !important;
    }
    .email-box2 {
        width: 100%!important;
        margin-top: 30px;
    }
    .add-copy-link-main {
        display: block;
        /* width: 100%; */
        align-items: center;
        justify-content: space-between;
    }
    .header-btn-login2 .MuiButton-containedSizeMedium, .header-btn-login2 .MuiButton-containedSizeMedium:hover {
        min-width: 200px;
    }
    .profile-set {
        margin: 5px 8px 0;
    }
    .coinlogs-ul2 {
        left: -273px;
        width: 345px !important;
    }
    .notifiction-box{
        overflow: auto;
        height: 237px;
      }
      .coinlogs-ul {
        position: absolute;
        top: 17px;
        left: -187px;
    }
        .coinlogs-ul li {
            padding: 0px 20px;
           
        }
    .icone-noti, .icone-noti img {
        height: 45px;
        width: 45px;
        object-fit: cover;
    }
    .coinlogs-ul2 {
        left: -247px;
        width: 314px !important;
        z-index: 999;
    }
    .res-mob-set-btn{
        padding: 0px 0px 54px;
    }
    .dot-box2 {
        height: 74px;
        width: 74px;
        background: #39B28E;
        border-radius: 50%;
    }
    .roadmap-border2 {
        height: 90px;
        width: 90px;
        left: 125%;
    }
    
    .dashboard-box {
        padding: 30px 0px 0px 0px;
    }
    .dashbord-ul li {
        width: calc(100% / 3);
        margin-bottom: 90px;
    }
    .squrebox-main {
        top: 45px;
    }
    .dashbord-ul {
        display: block;
        flex-wrap: wrap;
    }

    .userteamsandconditions-text-inner p {
        font-size: 17px;
        text-align: left;
    }
    .userteamsandconditions-text {
        padding: 30px 0px 30px 0px;
    }
    .mob-menu-set .MuiPaper-elevation {
        height: 100vh !important;
        width: 260px;
        max-height: 100%;
    }

    .mob-menu {
        display: none;
    }

    .meet-box .meet-cards {
        margin-right: 0px;
    }

    .website p {
        font-size: 20px;
        line-height: 25px;

    }

    .third-phase-sec {
        padding: 36px 0px 36px 0px;
    }

    .team-name p {
        font-size: 16px;
        line-height: 28px;
    }

    .website-inner-text p {
        padding: 10px 0px 6px 0px;
        font-size: 13px;
        line-height: 23px;
        text-align: left;
    }

    .third-phase-section {
        height: 600px;
    }

    .team-position p {
        font-size: 17px;
    }

    .tabel-box .icolab-tabel {
        min-width: 700px;
    }

    .main-titel {
        font-size: 20px !important;
        line-height: 32px !important;
    }

    .client-inner {
        font-size: 14px !important;
        line-height: 22px !important;
    }

    .up-coming-main-box {
        width: 100%;
        min-width: 100%;
    }

    .up-coming-main-text p {
        font-size: 20px;
        line-height: 30px;
    }

    .up-coming-main-text-inner p {
        font-size: 16px;
        line-height: 20px;
    }

    .day-main-box {
        padding: 29px 0px 29px 0px;
        flex-wrap: wrap;
        width: 100%;
    }

    .up-coming-main-box {
        height: 380px;
        padding: 30px 15px 20px 15px;
    }

    .clender-box-inner p {
        font-size: 20px;
        line-height: 25;
    }

    .day-text p {
        font-size: 11px;
        line-height: 18px;
        margin-right: 15px;
    }

    .usd-text p {
        font-size: 22px;
        line-height: 30px;
    }

    .price-text p {
        font-size: 15px;
        line-height: 30px;
    }

    .ico-main-img-box img {
        width: 350px;
        height: 350px;
        object-fit: cover;
    }

    .ico-sec {
        padding: 70px 0px 40px 0px;
    }

    .ico-solution p {
        font-size: 20px;
        line-height: 30px;
    }

    .ico-section {
        background-image: none;
        height: auto;
    }

    .ico-solution-inner p {
        padding: 20px 0px 20px 0px;
        font-size: 13px;
        line-height: 28px;
    }

    .icolab-features-section {
        height: auto;
        /* padding: 0px 15px; */
    }

    .icolab-features p {
        font-size: 15px;
        line-height: 30px;
    }

    .icolab-features-card-box {
        display: block;
        padding: 40px 0px 60px 0px;
    }

    .icolab-features-card {
        width: 100%;
    }

    .mlr-30 {
        margin: 20px 0px;
    }

    .ico-calender p {
        font-size: 20px;
        line-height: 31px;
    }

    .ico-calender-section {
        height: auto;
        /* padding: 0px 15px; */
        min-height: 520px;
    }

    /* .icolab-tabel.css-quj9j6-MuiTable-root {
            min-width: 621px;
        } */
    .icolab-tabel.MuiTable-root {
        min-width: 850px;
    }

    .icolab-tabel tr th:nth-child(1) {
        width: 154px;
        padding-left: 30px;
    }

    .icolab-tabel tr th:nth-child(2) {
        width: 145px;
    }

    .icolab-tabel tr th {
        font-size: 15px;
        line-height: 20px;
    }

    .icolab-tabel tr td {
        font-size: 8px;
        line-height: 16px;
    }

    .meet-box {
        margin: -170px 0px 0 0px;
    }

    .meet-img-inner {
        height: 250px;
        width: 100%;
    }

    .meet-text-box {
        width: 100%;
        height: 114px;
    }

    .team-img {
        display: block;
    }

    .res-mr-30 {
        margin-right: 0px;
        padding: 15px 0px;
    }

    .meet-our-team-section-img {
        height: auto;
    }

    .meet-our-team-section {
        min-height: 260px;
        height: auto;
    }

    /* .client-section {
            padding: 0px 15px;
        } */
    .client-about-main {
        flex-wrap: wrap;
    }

    .mt-111 {
        /* margin-top: -75px; */
        margin-top: 0px;
    }

    .client-box {
        margin-top: 20px;
    }

    .res-client-box {
        margin: 20px 0px 100px 0px;
    }

    .client-about-img {
        height: 120px;
        width: 120px;
    }
    .client-about-img2 {
        height: 120px;
        width: 120px;
    }

    .openicone-img {
        height: 30px;
        width: 40px;
    }

    .openicone-text {
        font-size: 14px !important;
        line-height: 23px !important;
    }

    .openicone-inner-text {
        /* padding-top: 11px; */
            padding-top: 103px;
    }
    .openicone {
        position: absolute;
        left: 25px;
        top: 126px;
    }
    .client-inner-text {
        padding-bottom: 40px;
    }
    .owl-theme-new.owl-theme2.owl-theme .owl-nav.disabled+.owl-dots {
        margin-top: -69px;
    }
    .about-name {
        font-size: 18px !important;
        line-height: 22px !important;
        padding: 10px 0px 7px 0px;
    }

    .about-position {
        font-size: 16px !important;
        line-height: 24px !important;
    }

    .client-section-box {
        padding: 53px 0px 20px 0px;
    }

    .sub-text {
        font-size: 20px !important;
        line-height: 30px !important;
    }

    .sub-inner-text {
        font-size: 13px !important;
    }

    .sub-inner {
        padding: 11px 0px 30.2px 0px;
    }

    .email-small-btn {
        position: absolute;
        right: 0px;
    }

    .sub-box {
        padding: 50px 0px 50px 0px;
    }

    .subscribe-btn button {
        min-width: 110px;
        font-size: 15px;
    }

    .email-img-big {
        min-height: 287px;
        height: 100%;
    }

    .container-set {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .css-qfzrcq-MuiGrid-root>.MuiGrid-item {
        padding-left: 0px !important;
    }

    .footer-logo {
        height: 100px !important;
        width: 100px !important;
    }

    .icolab-icone {
        padding: 18.54px 0px 18.54px 0px;
    }

    .icolab-features-inner-text p {
        padding: 15px 20px 15px 20px;
    }

    .icolab-features-inner p {
        font-size: 14px;
        padding-top: 6px;
    }

    .icolab-tabel tr td,
    .icolab-tabel tr th {
        padding-top: 18px;
        padding-bottom: 18px;
    }

    .learn-now-btn button {
        min-width: 200px;
    }

    .frequently-section {
        padding: 0px 0px 20px 0px;
    }

    .set-clom li {
        width: calc(100% / 1);
    }

    .pb-73 {
        padding-bottom: 17px;
    }

    .dot-box {
        height: 15px;
        width: 15px;
    }

    .roadmap-text p {
        padding: 30px 0px 7px 0px !important;
    }

    .dot-text p {
        font-size: 15px;
        line-height: 23px;
        margin-left: 11px;
    }

    .text-box-inner p {
        font-size: 13px;
        line-height: 23px;
        padding-left: 20px;
    }

    .day-clender-box {
        width: calc(100% / 4 - 14px);
        margin: 0px 9px;
    }

    .clender-box {
        width: 100%;
        height: 66px;
    }

    .line {
        width: 11px;
    }

    .round {
        width: 100px;
        height: 81px;
    }

    .round-inner-text1 p {
        font-size: 10px;
        line-height: 17px;
    }

    .round-inner {
        width: 70px;
        height: 70px;
        padding: 11.61px 0px 9.93px 0px;
    }

    .roadmap-text-inner p {
        font-size: 13px;
        line-height: 22px;

    }

    .content p {
        min-width: 175px;
        min-height: 20px;
        padding: 15px;
        font-size: 11px;
        line-height: 16px;
    }

    .round-inner-text2 p {
        font-size: 9px;
        line-height: 11px;
    }

    /* .container {
            padding: 0px !important;
        } */
    .col4 {
        margin-left: 15.34px;
    }

    .roadmap-section {
        min-height: 950px;
    }

    .roadmap-main-section {
        padding: 53px 0px 50px 0px;
        margin: 0px -15px 0px -15px;
    }

    .res-pt-30 {
        padding-top: 30px;
    }

    .mob-menu-img {
        height: 30px;
        width: 30px;
        object-fit: contain;
    }

    .logo {
        height: 50px;
        width: 60px;
    }

    .header-set {
        height: 70px !important;
    }

    .css-1pz7awu-MuiButtonBase-root-MuiIconButton-root {
        padding: 0px !important;
    }

    .ico-solution-box {
        margin-left: 0px;
    }

    .logoset .MuiGrid-item {
        padding-left: 0px !important;
    }

    .logoset .MuiGrid-container {
        width: 470px;
        margin-left: -13px;
    }

    /* about page */
    .blockchain-main-img-box img {
        width: 100%;
        height: 300px;
        object-fit: contain;
        margin-top: 0px;
        min-width: 300px;
        min-height: 300px;
    }

    .blockchain-sec {
        padding: 40px 15px 40px 15px;
    }

    .about-text-inner p {
        font-size: 15px;
        line-height: 25px;
    }

    .about-text p {
        font-size: 20px;
        line-height: 11px;
        padding-bottom: 5px;
    }

    .about-third-phase-section {
        height: 200px !important;
        min-height: 216px !important;
    }

    /* login page */
    .login-main-box {
        /* background-image: none; */
        display: flex;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .login-box {
        width: 333px;
        height: auto;
        margin: auto;
        /* min-height: 530px; */
        min-height: 400px;
    }

    .login-but-account button {
        min-width: 325px !important;
        width: 325px;
    }

    .login-but-account button:hover {
        min-width: 325px !important;
        width: 325px;
    }

    .login-logo {
        padding: 20px 0px 0px 0px;
        margin-bottom: 10px;
        width: 100%;
        min-width: 90px;
        height: 99px;
    }

    .login-inner-text p {
        font-size: 20px;
        line-height: 30px;
        text-align: left;
        margin-bottom: 15px;
    }

    .connect-title p {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 6px;
    }

    .user-box {
        margin-bottom: 10px;
    }

    .password-box {
        margin-bottom: 13px;
    }

    .forgot-text p {
        font-size: 17px;
        line-height: 25px;
        padding: 15px 0px 12px 0px;
    }

    .signup-text p {
        color: #040404;
        font-size: 13px;
        line-height: 26px;
        font-family: 'Poppins-medium';
    }

    .signup-text span {
        /* color: hsl(0, 0%, 2%); */
        font-size: 13px;
        line-height: 26px;
        font-family: 'Poppins-medium';
    }

    .backto-text {
        color: #040404;
        font-size: 13px;
        line-height: 27px;
        font-family: 'Poppins-medium';
        padding-bottom: 33px;
    }

    /* / register page start / */
    .login-container {
        padding: 0 10px !important;
    }

    .login-box {
        width: 100%;
    }

    .first-last {
        margin-bottom: 0px;
        display: flex;
        flex-direction: column;
    }

    .sub-right-box {
        margin-left: 0;
    }

    .connect-title p {
        margin-top: 6px;
    }

    .login-main-box {
        padding: 49px 15px;
    }

    .register-input .input-register .MuiInputBase-formControl {
        width: 320px !important;
        height: 45px;
    }

    .gap-cus-2 {
        width: 100%;
        margin: 2px 0px;
    }

    .remember-box {
        margin-top: 20px;
        margin-left: 0px;
    }

    .register-btn button {
        width: 325px !important;
        min-width: 325px;
        margin-bottom: 20px;
        margin-top: 30px;
    }

    .register-btn button:hover {
        width: 325px !important;
        min-width: 325px;
        margin-bottom: 20px;
        margin-top: 30px;
    }

    .signup-text p {
        width: 100%;
        margin: auto;
        max-width: 305px;
    }

    .login-logo {
        margin-bottom: 20px;
    }

    .login-inner-text p {
        font-size: 23px;
        margin-bottom: 9px;
    }

    .reset-text p {
        margin-bottom: 13px !important;
    }

    .reser-pw-btn {
        margin-top: 20px;
    }

    .reset-bg {
        height: 100%;
        background-image: url('../images/png/loginimg.png'), radial-gradient(closest-side at 72% 63%, #39B28E 0%, #87C443 100%);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    /* / register page end / */
    /* / contact page start / */

    .contact-bg {
        background-size: cover;
        background-position: center;
        height: 410px !important;
    }

    .big-text-contact {
        width: 100%;
        font-size: 22px;
    }

    .small-text-contact {
        font-size: 14px;
        line-height: 25px;
        width: 100%;
    }

    .spaces-contactus {
        padding: 30px 0;
    }

    .connect-us-text {
        font-size: 21px;
        margin-bottom: 20px !important;
    }

    .information-text,
    .connectus-text,
    .get-touch-text {
        font-size: 21px;
        line-height: 38px;
        margin-bottom: 20px !important;
    }

    .main-info {
        margin-bottom: 25px;
    }

    .main-right {
        margin-top: 25px;
    }

    .form-group .form-control .MuiInputBase-fullWidth {
        width: 100% !important;
        min-width: 100% !important;
    }

    .sub,
    .name {
        margin-bottom: 18px !important;
    }

    .mail-input {
        margin-left: 0;
    }

    .name-mail {
        margin-bottom: 18px !important;
        display: flex;
        flex-direction: column;
    }

    .msg textarea {
        width: 100% !important;
        min-width: 100%;
    }

    .info-num {
        font-size: 17px;
    }

    .name-text,
    .mail-text {
        font-size: 17px;
    }

    .msg {
        margin-bottom: 40px;
    }

    .logoset .css-1secc4u>.MuiGrid-item {
        padding-left: 0 !important;
    }

    /* / contact page end / */
    .form-control-2 .MuiInputBase-formControl {
        width: 100%;
        min-width: 100% !important;
    }

    .ico-section2 {
        background-image: none;
        height: auto;
        min-height: 460px;
    }
    .res-mob-home-set{
        margin-top: 60px;
    }
    .mlr-20 {
        margin: 20px 0px;
    }
    .banner-content .es-banner-title {
       
        font-size: 30px;
        line-height: 37px;
         margin-bottom: 0px; 
       
    }
    .wp-tabs-panel .css-19kzrtu {
        padding: 0px 0px 0px 0px;
    }
    .intro-title-box .intro-title {
        font-size: 31px;
        line-height: 23px;
        margin-bottom: 25px;
    }
    .mob-menu-left button {
        padding: 0px;
    }

    .logoset .MuiGrid-container .MuiGrid-item {
        padding-left: 0px !important;
    }

    .forgotpass-input {
        margin: 10px 0;
    }

    .email {
        margin: 18px 0px;
    }
    .coinlogs-btn {
        padding: 45px 0px;
        text-align: center;
    }
    .coinlogs-section {
        height: auto;
        /* min-height: 286px; */
    }
    .change-btn {
        padding: 0px 0px 30px 0px;
        text-align: end;
    }
    .change-btn-set {
        display: block;
    }
    .userdashbord-btn button, .userdashbord-btn button:hover {
        min-width: 167px !important;
        width: 100% !important;
        margin-bottom: 40px;
    }
    .copy-text2{
        font-size: 10px !important;
    }

}