* {
  box-sizing: border-box;
}


@font-face {
  font-family: 'Poppins-Regular';
  src: url(../fonts/poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: 'Poppins-bold';
  src: url(../fonts/poppins/Poppins-Bold.ttf);
}

@font-face {
  font-family: 'Poppins-medium';
  src: url(../fonts/poppins/Poppins-Medium.ttf);
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url(../fonts/poppins/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: 'Poppins-Light';
  src: url(../fonts/poppins/Poppins-Light.ttf);
}

@font-face {
  font-family: 'Poppins-italic';
  src: url(../fonts/poppins/Poppins-Italic.ttf);
}

body {
  font-family: 'Poppins-Regular';
}

.register-error{
  color: #ff0000 ;
  margin: 10px;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0px 15px 0px 15px !important;
}

.banner-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 539px;
  padding: 66px 0;
  width: 100%;
}

.banner-content .es-banner-title {
  color: #fff;
  font-family: Poppins-bold;
  font-size: 50px;
  line-height: 49px;
  margin-bottom: 22px;
  text-shadow: 0 3px 6px #00000029;
}

.header-set {
  height: 90px !important;
}

.header-box {
  padding-left: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-right: 0px !important;
}

/* .container-set{
padding-left: 0px !important;
padding-right: 0px !important;
} */
.header a {
  font-family: 'Poppins-medium';
  font-size: 18px;
  line-height: 37px;
  color: black;
  text-decoration: none;
}

.logo {
  height: 70px;
  width: 94px;
  object-fit: contain;
}

.register {
  color: #39B28E !important;
}

.header-btn-login .css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background: transparent linear-gradient(270deg, #87C443 0%, #39B28E 100%) 0% 0% no-repeat padding-box;
  border: 1px #707070 !important;
  min-width: 147px;
  width: 100%;
  box-shadow: 0px 3px 6px #0000003B;
  opacity: 1;
}

.header-btn-login button:hover {
  /* box-shadow:none !important; */
  min-width: 147px !important;
  width: 100%;
  height: 50px !important;
  border-radius: 0 !important;
  text-transform: capitalize;
}

.header-btn-login button {
  background: transparent linear-gradient(270deg, #87C443 0%, #39B28E 100%) 0% 0% no-repeat padding-box;
  border: 1px #707070 !important;
  min-width: 147px !important;
  width: 100%;
  box-shadow: 0px 3px 6px #0000003B;
  opacity: 1;
  height: 50px !important;
  border-radius: 0 !important;
  text-transform: capitalize;
  font-size: 18px;
  font-family: 'Poppins-medium';
}

.footer-img {
  background-image: url('../images/png/footerbg.png');
  width: 100%;
  height: 268px;
  background-repeat: no-repeat;
  background-size: cover;

}

.footer-logo {
  height: 126px !important;
  width: 126px !important;
  object-fit: contain;
  align-items: center;
  margin-top: 31px !important;
}

.logoset {
  margin-top: 37px !important;
}

.copy-text {
  font-size: 14px;
  color: #030302;
  font-family: 'Poppins-Regular';
  line-height: 28px;
}

.email-img-big {
  height: 348px;
  background-image: url('../images/png/emailimg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.copytext-box {
  width: 100%;
  height: 318px !important;
  align-items: center;

}

.sub-text {
  font-size: 45px !important;
  color: #FFFFFF !important;
  line-height: 55px !important;
  font-family: 'Poppins-SemiBold' !important;
  text-align: center;
  margin: 0px;
}

.sub-box {
  padding-top: 52px;
  padding-bottom: 67.8px;
}

.sub-inner {
  padding: 11px 0px 52.2px 0px;
}

.sub-inner-text {
  font-size: 15px !important;
  font-family: 'Poppins-Regular' !important;
  line-height: 22px !important;
  color: #FFFFFF;
  max-width: 531px;
}

.sub-box-set {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sub-box-set p {
  width: 100%;
  max-width: 523px;
  margin: auto;
}

.textfield-set {
  /* height: 64px; */
  width: 100%;
  max-width: 631px;
  background-color: white;

}

.email-box .MuiInputBase-colorPrimary {
  border: 1px solid #F9C114 !important;
  border-radius: 0px !important;
}

.email-box .MuiInputBase-colorPrimary:hover {
  border: 1px solid #F9C114 !important;
  border-radius: 0px !important;
}

.client-section {
  background: #F1F5ED 0% 0% no-repeat padding-box;
}

.main-titel {
  font-size: 45px !important;
  line-height: 63px !important;
  font-family: 'Poppins-SemiBold' !important;
  color: black;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
}

.client-section-box {
  /* padding: 53px 0px 103px 0px; */
  padding: 53px 0px 50px 0px;
}

.client-inner {
  font-size: 15px !important;
  line-height: 22px !important;
  color: #000000;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px !important;
  font-family: 'Poppins-Regular' !important;
  max-width: 522px;
}

.client-inner-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 11px;
  padding-bottom: 120px;
}

.client-about {
  background: transparent linear-gradient(271deg, #87C443 0%, #39B28E 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000047;
  border-radius: 21px;
  opacity: 1;
  max-width: 540px;
  width: 100%;
  min-height: 410px;

}

.mr-30 {
  margin-right: 30px;
}

.client-about-main {
  display: flex;
  /* justify-content: center; */
  justify-content: space-between;
  /* align-items: center; */
}

.client-about-img {
  height: 156px;
  width: 156px;
  object-fit: cover;
  box-shadow: 2px -2px 22px #39B28EA1;
  border-radius: 50%;
}

/* .mt-111 {
  margin-top: -111px;
} */
.mt-111 {
  margin-top: 0px;
}

/* .client-box {
  margin-top: 63px;
} */
.client-box {
  margin-top: 85px;
}
.openicone-main2{
  padding-top: 36px;
}

.openicone-main {
  position: relative;
}

.openicone {
  /* position: absolute;
  left: 25px;
  top: 0; */
  position: absolute;
    left: 25px;
    top: 56px;
}

.openicone-img {
  height: 60px;
  width: 80px;
  object-fit: cover;
}

.openicone-text {
  font-size: 25px !important;
  line-height: 30px !important;
  color: white !important;
  font-family: 'Poppins-SemiBold' !important;
}

.openicone-inner-text {
  max-width: 416px;
  padding-top: 36px;
  margin: auto;
}

.about-name {
  color: #FFFFFF;
  font-size: 25px !important;
  line-height: 28px !important;
  font-family: 'Poppins-medium' !important;
  /* padding-top: 22px; */
  padding: 20px 0px 7px 0px;
}

.about-position {
  font-size: 18px !important;
  line-height: 28px !important;
  color: white;
  font-family: 'Poppins-medium' !important;
  padding-bottom: 16px;
}

.meet-our-team-section {
  height: 342px;
  background-color: white;
}

.pt-55 {
  padding-top: 55px;
}

.meet-our-team-section-img {
  /* height: 288px; */
  height: 460px;
  background-color: #39B28E
}

.meet-img-inner {
  height: 261px;
  /* width: 255px; */
  min-width: 255px;
  object-fit: cover;
  margin-left: -30px;
  z-index: 0;
  box-shadow: 5px 3px 10px #191E1145;
}

.meet-text-box {
  width: 255px;
  height: 114px;
  background-color: #FFFFFF;
  box-shadow: 5px 3px 10px #191E1145;
}

.team-img {
  display: flex;
}

.team-name p {
  font-size: 25px;
  font-family: 'Poppins-medium';
  line-height: 32px;
  color: black;
  padding: 25px 30px 7px 30px;
  text-align: left;
}

.team-name.team-name-big p {
  padding: 25px 0px 7px 30px !important;
}

.team-position p {
  font-size: 18px;
  color: #94CA4C;
  line-height: 25px;
  font-family: 'Poppins-medium';
  text-align: left;
  padding: 0px 30px 25px 30px;
}

.team-back {
  background-color: #87C443;
}

.team-text-box {
  display: flex;
}

.team-border {
  border-left: 30px solid #87C443;
  z-index: 2;

}

.team-border-next {
  border-left: 30px solid #3EDFAF;
  z-index: 2;

}

.meet-box {
  margin-top: -170px;
}

.icolab-features-section {
  background: transparent linear-gradient(272deg, #87C443 0%, #39B28E 100%) 0% 0% no-repeat padding-box;
  /* height: 584px; */
  min-height: 644px;
  height: auto;
}

.icolab-features p {
  color: white;
  font-size: 55px;
  line-height: 76px;
  font-family: 'Poppins-medium';
}

.icolab-features-inner p {
  font-size: 15px;
  color: white;
  max-width: 523px;
  line-height: 22px;
  text-align: center;
  padding-top: 10px;
}

.icolab-features-inner-box {
  display: flex;
  justify-content: center;
}

.icolab-features-card {
  /* height: 244px; */
  width: 350px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
}

.icolab-features-card-box {
  padding: 44px 0px 109px 0px;
  display: flex;
  justify-content: center;
}

.icolab-icone {
  padding: 34.54px 0px 18.54px 0px;
}

.icolab-icone-img {
  width: 32px;
  height: 19px;
}

.icolab-features-text p {
  color: #282C24;
  font-size: 20px;
  line-height: 28px;
  font-family: 'Poppins-medium';
}

.icolab-features-inner-text p {
  padding: 18px 23px 26px 23px;
  color: #717171;
  line-height: 25px;
  text-align: center;
  max-width: 360px;
  font-family: 'Poppins-Regular';
}

.icolab-features-inner {
  display: flex;
  justify-content: center;
}

.mlr-30 {
  margin: 0px 30px;
}

.ico-section {
  background-image: url('../images/png/ico.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 712px;
  min-height: 712px;
}

.ico-section2 {
  background-image: url('../images/png/iochome.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: auto;
  min-height: 712px;
}

.grid-main .grid-item {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
}

.ico-sec {
  padding: 86px 0px;
  /* margin-left: -15px;
  margin-right: -15px; */
}

.ico-main-box {
  display: flex;
}

.ico-solution p {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  text-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  font-size: 35px;
  line-height: 43px;
  font-family: 'Poppins-bold';

}

.ico-solution-inner p {
  /* padding: 31px 0px 103px 0px; */
  padding: 0px 0px 8px 0px;
  color: #000000;
  font-size: 20px;
  line-height: 43px;
  text-align: left;
  font-family: 'Poppins-Regular';
  max-width: 525px;
}

.learn-now-btn button {
  background: transparent linear-gradient(270deg, #E1C820 0%, #F7931E 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000003B;
  color: #FFFFFF;
  text-shadow: 0px 3px 6px #00000029;
  font-size: 18px;
  line-height: 37px;
  justify-content: center;
  min-width: 243px;
  display: flex;
  text-transform: capitalize;
  border-radius: 0px;
}

.ico-solution-box {
  margin-left: 20px;
  margin-top: 17px;
  max-width: 522px;
  width: 100%;
  /* margin-left: auto; */
}

.ico-calender-section {
  height: 648px;
  background-color: #F2F5F4;
}

.ico-calender p {
  font-size: 45px;
  line-height: 55px;
  font-family: 'Poppins-SemiBold';
}

.ico-calender-inner p {
  color: #000000 !important;
}

.frequently-section {
  /* background-image: url('../images/png/frequently.png'); */
  background-image: linear-gradient(#0b1f1ee3 100%, #0b1f1ee3 100%), url('../images/png/frequently.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 824px;

}

.frequently-text p {
  color: white;
}

/* btn */
.email-box {
  position: relative;
}

.email-small-btn {
  position: absolute;
  right: 241px;
}

.email-btn-set {
  display: flex;
  text-align: center;
  justify-content: center;
  height: 50px;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 15px !important;
}

.subscribe-btn button {
  background: transparent linear-gradient(270deg, #E1C820 0%, #F7931E 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000003b;
  opacity: 1;
  color: #FFFFFF;
  text-shadow: 0px 3px 6px #00000029;
  min-width: 243px;
  height: 48px;
  border-radius: 0px;
  font-size: 18PX;
  font-family: 'Poppins-Regular';
  text-transform: capitalize !important;
  line-height: 37px;
  /* border: 1px solid white; */
  margin-top: 1px;
}

/* end */
.pb-73 {
  padding-bottom: 73px;
}

.text-box-inner p {
  font-size: 15px;
  line-height: 25px;
  color: white;
  max-width: 498px;
  padding-left: 30px;
  border-left: 1px solid #87C443;
  padding-bottom: 10px;

}

.pt-17 {
  padding-top: 17px;
}

.dot-box {
  height: 19px;
  width: 19px;
  background: #FFFFFF;
  border-radius: 50%;
}

.fre-box-inner {
  display: flex;
  align-items: center;
}

.dot-text p {
  font-size: 20px;
  font-family: 'Poppins-medium';
  color: white;
  line-height: 30px;
  margin-left: 16px;
}

.dot-border {
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 5px;
}

.pl-16 {
  padding-left: 16px;
}

.set-clom {
  display: flex;
  flex-wrap: wrap;
}

.set-clom li {
  width: calc(100% / 2);
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.roadmap-section {
  background-image: url('../images/png/roademap.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 1198px;
}

.roadmap-text p {
  color: white;
  padding: 57px 0px 7px 0px !important;
}

.roadmap-text-inner p {
  font-size: 15px;
  color: white;
  max-width: 523px;
  line-height: 22px;
  text-align: center;
  font-family: 'Poppins-Regular';
  text-align: center;
  margin-top: 7px;
}

.roadmap-inner-box {
  display: flex;
  justify-content: center;
}

.roadmap-main-section {
  padding: 103px 0px 155.34px 0px;
}

ul.roadmap-ul {
  list-style-type: none;
  position: relative;
  padding: 0;
  max-width: 800px;
  margin: auto;
}

.roadmap-card {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

.dot {
  height: 13.29px;
  width: 13.29px;
  background: #87c443;
  border-radius: 50%;
}

.line {
  height: 1px;
  width: 60px;
  background: white;
}

.round {
  width: 138px;
  height: 113px;
  object-fit: cover;
}

.col4 {
  flex: 1 1;
  margin-left: 37.34px;
}

.content p {
  color: #020303;
  box-shadow: 0px 3px 6px #87c443;
  border-radius: 11px;
  background: white;
  min-width: 416px;
  min-height: 159px;
  padding: 25px 16px 26px 17px;
  font-size: 15px;
  line-height: 27px;
  max-width: 200px;
  font-family: 'Poppins-Regular';
}

ul.roadmap-ul li {
  margin-bottom: 20px;
}

ul.roadmap-ul li:nth-child(odd) .round {
  background-image: url('../images/png/roadmap2.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

}

ul.roadmap-ul li:nth-child(even) .round {
  background-image: url('../images/png/roadmap3.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

}

.roadmap-ul li:nth-child(even) .roadmap-border .dot {
  background: #39b28e;
}

ul.roadmap-ul:after {
  content: "";
  display: block;
  height: 100%;
  width: 1px;
  background: #FFFFFF;
  position: absolute;
  left: 26px;
  top: 0;
}

.roadmap-border {
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 3px;
  background: #0b1f1e;
}

.round-inner {
  width: 82px;
  height: 82px;
  background-color: white;
  border-radius: 50%;
  padding: 8.61px 12px 10.93px 12px;
}

.round-set {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
}

.round-inner-text1 p {
  font-size: 22px;
  font-family: 'Poppins-semibold';
  color: #87C443;
  line-height: 38px;
}

.roadmap-ul li:nth-child(even) .col3 .round-set .round-inner-text1 p {
  color: #39b28e;
}

.round-inner-text2 p {
  font-family: 'Poppins-Regular';
  font-size: 9px;
  line-height: 11px;
  color: #060705;
}

.third-phase-section {
  background-image: url('../images/png/img1.png'), radial-gradient(closest-side at 72% 63%, #39B28E 0%, #87C443 100%);
  background-repeat: no-repeat;
  /* background-size: 100% 100%; */
  background-size: cover;
  /* height: 703px; */
  height: 627px;
  background-position: center;

}

.comming-main {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 640px;
  height: 100%;
}
.comming-main h4 {
  font-size: 52px;
  line-height: 41px;
  color: #fff;
  font-family: 'Poppins-medium';
  text-transform: capitalize;
}
.comming-section{
  height: 100vh;
}



.website p {
  color: #FFFFFF;
  text-shadow: 0px 3px 6px #00000029;
  font-size: 35px;
  line-height: 43px;
  text-align: left;
  max-width: 420px;
  font-family: 'Poppins-bold';
}

.website-inner-text p {
  font-family: 'Poppins-Regular';
  font-size: 20px;
  line-height: 43px;
  color: #FFFFFF;
  text-align: left;
  padding-top: 23px;
  max-width: 527px;
}

.up-coming-main-box {
  min-width: 523px;
  height: 531px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 13px #87C44391;
  padding: 54px 29px 52px 34px;
  width: 531px;
  margin-left: auto;
}

.pt-10 {
  padding-top: 10px;
}

.ico-main-img-box img {
  width: 540px;
  height: 540px;
  object-fit: cover;
}

.up-coming-main-text p {
  color: #091C16;
  font-size: 35px;
  font-family: 'Poppins-bold';
  line-height: 43px;
  text-align: left;
}

.up-coming-main-text-inner p {
  font-family: 'Poppins-Regular';
  color: #000000;
  font-size: 20px;
  line-height: 28px;
  text-align: left;
}

.day-main-box {
  padding: 54px 0px 29px 0px;
  display: flex;
}

.clender-box {
  width: 90px;
  height: 90px;
  background: #FFFFFF;
  box-shadow: 0px 1px 6px #39B28EAB;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.day-text p {
  color: #010101;
  font-family: 'Poppins-Regular';
  font-size: 15px;
  line-height: 21px;
  padding-top: 5px;
}

.price-text p {
  font-size: 18px;
  line-height: 43px;
  color: #39B28E;
  font-family: 'Poppins-Regular';
}

.usd-text p {
  font-size: 35px;
  line-height: 43px;
  font-family: 'Poppins-bold';
  color: #39B28E;
  padding-left: 11px;
}

.price-inner {
  display: flex;
}

.pt-52 {
  padding-top: 52px;
}

.day-clender-box {
  width: calc(100% / 4 - 26px);
  margin: 0 17px;
}

.day-main-box .day-clender-box:last-child {
  margin-right: 0;
}

.day-main-box .day-clender-box:first-child {
  margin-left: 0;
}

.icolab-tabel tr th {
  text-align: left;
  background: #39B28E;
  font-size: 23px;
  color: white;
  font-family: 'Poppins-medium';
  line-height: 28px;
  border-radius: 0px !important;
}

.icolab-tabel tr td {
  text-align: left;
  border-bottom: none !important;
  font-family: 'Poppins-medium';
  color: #000000;
  font-size: 15px;
  line-height: 28px;

}

.icolab-tabel tr td,
.icolab-tabel tr th {
  padding-top: 23px;
  padding-bottom: 22px;
  padding-left: 30px;
}

.upcoming-color {
  font-family: 'Poppins-italic';
  color: #F7931E;
}

.completed-color {
  font-family: 'Poppins-italic';
  color: #87C443;
}

.clender-box-inner p {
  font-size: 25px;
  color: black;
  font-family: 'Poppins-SemiBold';
  line-height: 30;
}

.icolab-tabel tr th:nth-child(1) {
  width: 270px;
  padding-left: 30px;
}

.icolab-tabel tr th:nth-child(2) {
  width: 226px;
}

.icolab-tabel tr th:nth-child(5) {
  width: 202px;
}

.icolab-tabel tr th:last-child {
  width: 133px;
}

.position-relative {
  position: relative;
}

.progress-box {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.progress-color span {
  background: #39B28E !important;
  width: 100%;
  margin-right: 5px;
  flex: 0 0 125px;
}

.progress-color-up span {
  background: #EFEFEF !important;
  width: 100%;
  margin-right: 5px;
  flex: 0 0 125px;
}

.progress-color-up,
.progress-color {
  display: flex;
  align-items: center;
}

.tabel-box {
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%) !important;
}

.meet-box .meet-cards {
  margin-right: 30px;

}

.mob-menu-set .MuiPaper-elevation {
  top: 0 !important;
  left: 0 !important;
  transition: none !important;
}

/* About page */
.about-third-phase-section {
  /* height: 475px !important;
  min-height: 475px !important; */
  height: 300px !important;
    min-height: 300px !important;
}

.about-text-main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-text p {
  font-size: 35px;
  color: #FFFFFF;
  line-height: 43px;
  font-family: 'Poppins-bold';
  text-shadow: 0px 3px 6px #00000029;
  padding-bottom: 21px;
}

.about-text-inner p {
  font-size: 20px;
  line-height: 43px;
  font-family: 'Poppins-Regular';
  color: white;
  max-width: 552px;
}

.blockchain-text p {
  max-width: 468px;
}

.blockchain-text-inner p {
  padding: 18px 0px 33px 0px !important;
}

.blockchain-sec {
  padding: 109px 0px 83px 0px;
  margin-left: -15px;
  margin-right: -15px;
}

.blockchain-section {
  min-height: 712px !important;
}

/* .blockchain-solution-box {
margin-left: 20px;
} */
.blockchain-main-img-box img {
  width: 500px;
  height: 500px;
  object-fit: cover;
  display: flex;
  margin-top: 28px;
}

.header.css-1f3i64k {
  /* width: 768px; */
  width: 800px;
  align-items: center;
  justify-content: right !important;
}

/* login page */
.login-box {
  width: 690px;
  /* height: 697px; */
  margin: auto;
  background: #FFFFFF;
  box-shadow: 0px 3px 11px #39B28E7A;
  border-radius: 12px;
}

.login-main-box {
  min-height: 100vh;
  padding: 49px 0px;
  height: 100%;
  background-image: url('../images/png/loginimg.png'), radial-gradient(closest-side at 72% 63%, #39B28E 0%, #87C443 100%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
}

.login-logo {
  width: 100%;
  min-width: 122px;
  height: 92px;
  object-fit: cover;
  padding: 42px 0px 0px 0px;
  margin-bottom: 57px;
}

.login-inner-text p {
  font-size: 28px;
  line-height: 42px;
  color: #040404;
  text-align: left;
  font-family: 'Poppins-bold';
  margin-bottom: 33px;
}

.connect-title p {
  text-align: left !important;
  font-size: 16px;
  color: #040404;
  font-family: "Poppins-Light";
  line-height: 25px;
  margin-bottom: 6px;
}

.user-box {
  margin-bottom: 18px;
}

.password-box {
  margin-bottom: 24px;
}

.remember-box {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.login-but-account button:hover {
  /* box-shadow: none !important; */
  min-width: 362px !important;
  width: 362px;
  background: transparent linear-gradient(270deg, #87C443 0%, #39B28E 100%) 0% 0% no-repeat padding-box;
  border: 1px #707070 !important;
  color: white;
  font-size: 18px;
  line-height: 30px;
  font-family: 'Poppins-medium';
}

.login-but-account button {
  /* box-shadow: none !important; */
  min-width: 362px !important;
  width: 362px;
  background: transparent linear-gradient(270deg, #87C443 0%, #39B28E 100%) 0% 0% no-repeat padding-box;
  border: 1px #707070 !important;
  color: white;
  font-size: 18px;
  line-height: 30px;
  font-family: 'Poppins-medium';
}

.forgot-text p {
  font-size: 19px;
  line-height: 29px;
  color: #39B28E;
  font-family: 'Poppins-bold';
  padding: 15px 0px 40px 0px;
}

.signup-text p {
  color: #040404;
  font-size: 18px;
  line-height: 27px;
  font-family: 'Poppins-medium';
  padding-bottom: 33px;
}

.signup-text span {
  color: #39B28E;
  font-size: 18px;
  line-height: 27px;
  font-family: 'Poppins-medium';
  padding-bottom: 33px;
}

.login-container {
  margin: 0 auto;
  padding: 0px 27px 0px 27px !important;
}

.checkbox span {
  padding: 0px;
  width: 21px;
  height: 21px;
}

.checkbox-title p {
  text-align: left !important;
  font-size: 16px;
  color: #040404;
  font-family: "Poppins-Light";
  line-height: 25px;
  margin-left: 12px;
}

.checkbox .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #000000 !important;
}

.header li {
  font-family: 'Poppins-medium';
  font-size: 18px;
  line-height: 37px;
  color: black;
  text-decoration: none;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #BBB9B9 1px solid !important;
}

/* meet */
/* / contact page start / */

/* .contact-bg {
  background-image: url(../images/png/contactbg.png), radial-gradient(closest-side at 72% 63%, #39B28E 0%, #87C443 100%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 475px !important;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.big-text-contact {
  font-size: 35px;
  line-height: 45px;
  text-shadow: 0px 3px 6px #00000029;
  color: #FFFFFF;
  font-family: 'Poppins-bold';
  width: 568px;
  margin: 0 0 21px 0 !important;
}

.small-text-contact {
  font-size: 20px;
  line-height: 43px;
  color: #FFFFFF;
  font-family: 'Poppins-Regular';
  width: 568px;
  margin: auto !important;
}

.main-contactus {
  background-color: aquamarine;
  height: 500px;
}


.grid-main .grid-item {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
  text-align: left;

}

.contact-us {
  background-color: gray;
}

.grid-main {
  margin-top: 0 !important;
}

.spaces-contactus {
  padding: 125px 0 52px 0;
}

.main-info {
  margin-bottom: 43px;
}

.connect-us-text {
  font-size: 29px;
  line-height: 43px;
  color: #000000;
  text-transform: uppercase;
  font-family: 'Poppins-bold';
  text-align: left;
  margin-bottom: 44px !important;
}

.information-text,
.connectus-text,
.get-touch-text {
  font-size: 25px;
  line-height: 38px;
  color: #040404;
  font-family: 'Poppins-SemiBold';
  margin-bottom: 22px !important;
}

.info-num {
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  font-family: 'Poppins-Regular';
}

.call {
  display: flex;
  margin-bottom: 26px;
}

.call-icon {
  width: 21.51px;
  height: 21.51px;
  object-fit: contain;
  margin-right: 19.49px;
}

.mail {
  align-items: center;
  display: flex;
}

.mail-icon {
  display: flex;
  align-items: center;
}

.fb-icon {
  width: 10.46px;
  height: 19.54px;
}

.icon {
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #666666;
  border-radius: 50%;
  margin-right: 12px !important;
}

.social-icons {
  display: flex;
  align-items: center;
}

.main-right {
  margin-top: 13px;
}

.name-mail {
  display: flex;
  margin-bottom: 35px !important;
}

.mail-input {
  margin-left: 16px;
}

.name-text,
.mail-text {
  font-size: 19px;
  line-height: 29px;
  color: #040404;
  font-family: 'Poppins-Light';
}


.form-group-2 .form-control-2 .MuiInputBase-fullWidth {
  border: 0px solid #BBB9B9;
  width: 357px !important;
  border-radius: 0;
}

.form-control .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: none !important;
}

.sub .subject-box .MuiInputBase-formControl {
  border-radius: 0;
  width: 730px !important;
}

.sub {
  margin-bottom: 35px !important;
}

.msg {
  margin-bottom: 71px;
}

.msg textarea {
  padding: 5px 13px;
  min-height: 113px !important;
  background: transparent;
  resize: none;
  min-width: 730px;
  font-size: 15px;
  line-height: 29px;
  color: #040404;
  font-family: 'Poppins-Regular';
  border: 1px solid #BBB9B9;
}

.msg textarea:focus {
  border-radius: none !important;
  border: 1px solid #BBB9B9;
}

.main-right .text {
  margin-bottom: 5px;
}

.send .form-btn {
  border-radius: 0;
  float: right;
  width: 193px !important;
  height: 50px;
  box-shadow: 0px 5px 10px #0303021A;
}

.send .form-btn:hover {
  box-shadow: 0px 5px 10px #0303021A;
}

/* / contact page end / */


/* / Register page start / */

/* .login-main-box {
  min-height: 100vh;
  padding: 49px 0;
  height: 100%;
  background-image: url('../images/png/regi.png'), radial-gradient(closest-side at 72% 63%, #39B28E 0%, #87C443 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
} */

.login-box {
  width: 690px;

  margin: auto;
  background: #FFFFFF;
  box-shadow: 0px 3px 11px #39B28E7A;
  border-radius: 12px;
}

/* .login-logo {
  width: 100%;
  min-width: 122px;
  height: 92px;
  object-fit: cover;
  padding: 42px 0px 0px 0px;
  margin-bottom: 57px;
} */
.login-logo {
  width: 100%;
  min-width: 122px;
  height: 30px;
  object-fit: cover;
  padding: 11px 0px 0px 0px;
  margin-bottom: 50px;
}

/* .login-inner-text p {
  font-size: 28px;
  line-height: 42px;
  color: #040404;
  text-align: left;
  font-family: 'Poppins-bold';
  margin-bottom: 33px;
} */
.login-inner-text p {
  font-size: 28px;
  line-height: 42px;
  color: #040404;
  text-align: left;
  font-family: 'Poppins-bold';
  margin-bottom: 10px;
}

.connect-title p {
  text-align: left !important;
  font-size: 16px;
  color: #040404;
  /* font-family: 'Poppins-Regular'; */
  font-family: 'Poppins-Light';
  line-height: 25px;
  margin-bottom: 6px;
}

.user-box {
  margin-bottom: 18px;
}

/* .password-box {
  margin-bottom: 24px;
} */
.password-box {
  margin-bottom: 13px;
}

.remember-box {
  display: flex;
  align-items: center;
  margin-left: -3px;
}

.login-but-account button:hover {
  box-shadow: none !important;
  min-width: 362px !important;
  width: 362px;
  background: transparent linear-gradient(270deg, #87C443 0%, #39B28E 100%) 0% 0% no-repeat padding-box;
  border: 1px #707070 !important;
  margin: auto;
}

.login-but-account button {
  box-shadow: none !important;
  min-width: 362px !important;
  width: 362px;
  background: transparent linear-gradient(270deg, #87C443 0%, #39B28E 100%) 0% 0% no-repeat padding-box;
  border: 1px #707070 !important;
  opacity: 1;
  text-transform: capitalize;
  margin: auto;
}

.input-register .MuiInputBase-fullWidth {
  border-radius: 0;
}

/* .forgot-text p {
  font-size: 19px;
  line-height: 29px;
  color: #39B28E;
  font-family: 'Poppins-bold';
  padding: 15px 0px 40px 0px;
} */
.forgot-text p {
  font-size: 19px;
  line-height: 29px;
  color: #39B28E;
  font-family: 'Poppins-bold';
  padding: 15px 0px 15px 0px;
}

.signup-text p {
  color: #040404;
  font-size: 18px;
  line-height: 27px;
  font-family: 'Poppins-medium';
  padding-bottom: 33px;
}

.signup-text span {
  color: #39B28E;
  font-size: 18px;
  line-height: 27px;
  font-family: 'Poppins-medium';
  padding-bottom: 33px;

  padding-left: 5px;

}

.signup-text a,
.signup-text a:hover {
  text-decoration: none;
}

.login-container {
  margin: 0 auto;
  padding: 0px 27px 0px 27px !important;
}

.checkbox span {
  padding: 0px;
  width: 21px;
  height: 21px;
}

.checkbox-title p {
  text-align: left !important;
  font-size: 16px;
  color: #040404;
  font-family: 'Poppins-Regular';
  line-height: 25px;
  margin-left: 12px;
}

.checkbox .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #000000 !important;
}

.main-login {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.register-input .input-register .MuiInputBase-formControl {
  width: 310px !important;
  height: 50px;
}

.first-last {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
}

.sub-right-box {
  margin-left: 17px;
}
.center-box{
  display: flex;
  justify-content: center;
  align-items: center;
}
.register-btn button {
  width: 358px !important;
  box-shadow: 0px 5px 10px #0303021A;
  margin-bottom: 23px;
  margin-top: 33px;
  font-family: 'Poppins-medium';
}

.register-btn button:hover {
  width: 358px !important;
  box-shadow: 0px 5px 10px #0303021A;
}

.remember-box span {
  padding: 0;
}

.remember-box span .MuiSvgIcon-fontSizeMedium {
  color: #000000 !important;
}

.inline-checkbox span {
  margin-left: 9px;
  margin-right: 12px;
  font-family: 'Poppins-Light';
  font-size: 16px;
  line-height: 15px;
  color: #040404;
}

.send button:hover {
  box-shadow: 0px 5px 10px #0303021A;
  background: transparent linear-gradient(270deg, #87C443 0%, #39B28E 100%) 0% 0% no-repeat padding-box;
  border: 1px #707070 !important;
}

.send button {
  box-shadow: 0px 5px 10px #0303021A;
  background: transparent linear-gradient(270deg, #87C443 0%, #39B28E 100%) 0% 0% no-repeat padding-box;
  border: 1px #707070 !important;
  opacity: 1;
  text-transform: capitalize;
  font-family: 'Poppins-medium';
  font-size: 18px;
  line-height: 30px;
}

.register-btn button:hover {
  box-shadow: none !important;
  background: transparent linear-gradient(270deg, #87C443 0%, #39B28E 100%) 0% 0% no-repeat padding-box;
  border: 1px #707070 !important;
}

.register-btn button {
  box-shadow: none !important;
  background: transparent linear-gradient(270deg, #87C443 0%, #39B28E 100%) 0% 0% no-repeat padding-box;
  border: 1px #707070 !important;
  opacity: 1;
  text-transform: capitalize;
  font-size: 18px;
  line-height: 30px;
  font-family: 'Poppins-medium';
}

/* / Register page end / */


.form-control-3 .MuiInputBase-formControl {
  border-radius: 0;
  width: 100% !important;
  min-width: 357px !important;
}

.form-control-login .MuiInputBase-colorPrimary {
  border-radius: 0;
}

.login-but-account,
.register-btn button {
  border-radius: 0;
  display: flex;
    margin: auto;
}

.remember-box span {
  margin: 0;
}

.remember-box .MuiFormControlLabel-label {
  padding-left: 12px;
}

.remember-box label {
  margin: 0;
}

.textfield-set .MuiInputBase-colorPrimary {
  font-size: 15px;
  font-family: 'Poppins-Regular';
  padding-left: 6px;
  color: #B1B1B1;
  font-family: 'Poppins-Regular';
  padding-left: 6px;
  line-height: 25px;
}

.logoset .MuiGrid-container {
  width: 470px;
}

.remember-box svg {
  height: 21px;
  width: 21px;
  /* padding: 0px; */
}

.textfield-set .MuiInputBase-formControl fieldset {
  border: 0px;
}

.info-num .first-num {
  margin-bottom: 6px;
}

.header-box header {
  background-color: transparent !important;
}

.email-box input {
  height: 15px !important;
}

/* Forgot password page start */

.forgot-pass .form-forgot-pass .MuiInputBase-colorPrimary input {
  font-size: 16px;
  color: #040404;
  font-family: 'Poppins-light';
  line-height: 25px;
}

.backto-text-fotgot {
  margin-top: 20px;
}

.forgot-text .forgot-link {
  color: #39B28E;
  text-decoration: none;
}

.icon-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.backicon-main a {
  display: flex;
  margin-right: 5px;
  color: #040404 !important;

}

.backto-text .link-style2 {
  color: #040404 !important;
}

.backicon-main {
  transition: padding .5s
}

.backicon-main:hover {
  padding-right: 5px !important;
}

.reser-pw-btn {
  padding-bottom: 42px;
}


/* Forgot password page end */

.form-control-login .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.subject-box .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.form-control-3 .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.register-input .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

/* dashboarduser  3-10*/
.coinlogs-section {
  background-image: url('../images/png/roademap.png');
  background-repeat: no-repeat;
  background-size:cover;
  /* height: 703px; */
  min-height: 435px;
  height: auto;
}
.coinlogs-btn{
padding-top:65px;
padding-bottom: 58px;
text-align:end;
}

/* .header-btn-login2 .css-sghohy-MuiButtonBase-root-MuiButton-root , .header-btn-login2 .css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
  text-decoration: none;
  background: transparent linear-gradient(270deg, #87C443 0%, #39B28E 100%) 0% 0% no-repeat padding-box;
  border: 1px #707070 !important;
  min-width: 247px;
  height: 50px !important;
  box-shadow: 0px 3px 6px #0000003b;
  opacity: 1;
  text-transform: capitalize;
  font-size: 18px;
  line-height: 30px;
  font-family: 'Poppins-medium';
  border-radius: 0px !important;
} */

.header-btn-login2 a{
  text-decoration: none !important;
}
.coinlogs-tabel tr th:nth-child(1) {
  width: 170px;
  padding-left: 30px;
}
.coinlogs-tabel tr th:nth-child(2) {
  width: 170px;
  
}
.coinlogs-tabel tr th:nth-child(3) {
  width: 170px;
 
}
.coinlogs-tabel tr th:nth-child(4) {
  width: 170px;
 
}
.coinlogs-tabel tr th:nth-child(5) {
  width: 170px;
 
}
.coinlogs-tabel tr th:last-child {
  width: 150px;
}
.change-btn{
  padding: 43px 0px 60px 0px;
  text-align: end;
}
.change-btn2 button, .change-btn2 button:hover {
  min-width: 358px !important;
  width: 358px;
  border-radius: 0px;
  box-shadow: 0px 5px 10px #0303021A !important;
  height:48px;
}
  .change-btn-set{
    display: flex;
  }
  .change-btn3 button, .change-btn3 button:hover {
    min-width: 240px !important;
    width: 240px;
    color: #39b28e !important; ;
    background-color: white !important;
    margin-right: 40px;
    text-decoration: none;
  height: 50px !important;
  box-shadow: none;
  opacity: 1;
  text-transform: capitalize;
  font-size: 18px;
  line-height: 30px;
  font-family: 'Poppins-medium';
  }
  .userteamsandconditions-text{
    padding: 82px 0px 80px 0px ;
  }
  .userteamsandconditions-text-inner p{
    font-family: 'Poppins-Regular';
    font-size: 20px;
    line-height: 43px;
    text-align: left;
  }
  .text-inner2{
    margin-top: 20px;
  }
  .dashboard-inner-section{
/* min-height: 935px !important; */
min-height: 1091px !important;
  }
  .profile-section{
    padding: 43px 0px 75px 0px ;
  }
  .register-input2 .input-register .MuiInputBase-formControl {
    width: 100% !important;
    min-width: 548px !important;
}
.login-inner-text2 p{
  margin-bottom:33px !important ;
}
.sub-right-box2{
  margin-left: 16px;
}
.profile-btn{
  align-items: center;
    justify-content: space-between;
    display: flex;
    margin-bottom: 27px;
}
.dashboard-box{
  padding: 83px 0px 50px 0px;
}
.dot-box2 {
    height: 110px;
    width: 110px;
    background: #39B28E;
    border-radius: 50%;
}
.roadmap-border2 {
  border: 1px solid #39B28E;
  border-radius: 50%;
  padding: 3px;
  background: #0b1f1e;
  height: 120px;
  width: 120px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  z-index: 9;
  left: 78%;
}
.counter-text p{
  font-size: 21px;
    font-family: 'Poppins-SemiBold';
    color: white;
    text-align: center;
    line-height: 11px;
}
.roadmap-border2 .dot-box2{
  display: flex;
    align-items: center;
    justify-content: center;
}
.squre-box2 {
  width: 310px;
    height: 121px;
}
.squre-border2 {
  border: 1px solid #39B28E;
  padding: 3px;
  
}
.squre-border2 .squre-box2{
  display: flex;
  align-items: center;
  justify-content: center;
}
.squrebox-main{
position: absolute;
z-index: 0;
top: 55px;
}
.main-dashboard{
  position: relative;
}
.squre-box-set p{
  padding-top: 64px;
}
.dashbord-ul li {
  width: calc(100% / 3);
  margin-bottom: 100px;
  
}
.dashbord-ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.userdashbord-btn button, .userdashbord-btn button:hover {
  box-shadow: none !important;
  background: transparent linear-gradient(270deg, #E1C820 0%, #F7931E 100%) 0% 0% no-repeat padding-box;
  border: 1px #707070 !important;
  opacity: 1;
  text-transform: capitalize;
  font-size: 18px;
  line-height: 30px;
  font-family: 'Poppins-medium';
  width: 243px !important;
  box-shadow: 0px 3px 6px #0000003B;
    margin-bottom: 68px;
    font-family: 'Poppins-medium';
    padding: 13px 16px !important;
    border-radius: 0px !important;
}
.userdashbord-btn2 button, .userdashbord-btn2 button:hover{
  margin-bottom: 0px !important;
}
.userdashboard-tabel tr th:nth-child(1) {
  width: 127px;
  padding-left: 30px;
}  
.userdashboard-tabel tr th:nth-child(2) {
  width: 160px;
 
}
.userdashboard-tabel tr th:nth-child(3) {
  width: 100px;
 
}
.userdashboard-tabel tr th:nth-child(4) {
  width: 153px;
 
}
.userdashboard-tabel tr th:last-child {
  width:111px;
}
.myprofile-set p{
margin-bottom: 0px !important;
}
.userdashboard-tabel tr td{
  text-align: center;
    font-size: 17px;
    line-height: 27px;
    font-family: 'Poppins-medium';
}
.coinlogs-tabel tr td{
  text-align: center;
    font-size: 17px;
    line-height: 27px;
    font-family: 'Poppins-medium';
    border-radius: 0px !important;
}
.icone-profile{
  display: flex;
}
.icone-profile1 img{
 
    height: 18px;
    width: 18px;
}
.profile-set{
  margin: 12px 12px 0px;
}
.edit-icone{
  height: 15.66px;
    width: 15.66px;
    margin-right: 15px;
}
.coinlogs-ul{
  display: none;
  /* display: block; */
  
}
.coin-li:hover .coinlogs-ul{
  display: block;
}
.coin-li{
  position: relative;
}
.coinlogs-ul{
  position: absolute;
  top: 27px;
  left: -187px;
    background: white;
    /* padding-top: 23px; */
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000073;
    width: 245px;
    margin-top: 24px;
}
.coinlogs-ul2{
  left: -267px;
  width: 345px !important;
}
.coinlogs-ul li{
  font-size: 16px;
  font-family: 'Poppins-medium';
  line-height: 37px;
  padding: 10px 20px ;
  color: black;
 }
 .coinlogs-ul:before {
  content: '';
  height: 20px;
  width: 20px;
  position: absolute;
  background-color: #fff;
  top: -12px;
  left: 78%;
  border-top: #fff solid 2px;
  border-left: #fff solid 2px;
  transform: rotate(45deg);
  box-shadow: -1px -1px 3px -1px #00000073;
}
.coinlogs-ul:after {
  content: '';
  width: 100%;
  height: 31px;
  background: transparent;
  display: flex;
  position: absolute;
  top: -33px;
}
.notifications p{
  font-size: 16px;
    font-family: 'Poppins-medium';
    line-height: 37px;
    padding: 15px 1px 15px;
    color: black;
    text-align: left;
    border-bottom: 1px solid #BBB9B9;
    margin: 0px 20px;
 }
 .main-notification-inner{
  display: flex;
  
 }

 .icone-noti, .icone-noti img{
  height: 61px;
  width: 61px;
  object-fit: cover;
}
.noti-text p{
  font-size: 14px;
    line-height: 21px;
    font-family: 'Poppins-Light';
    white-space: normal;
    text-align: left;
    padding-left: 9px;
}
.text-number p{
  font-size: 10px;
    line-height: 16px;
    font-family: 'Poppins-Light';
    text-align: right;
    color:#484848;
}
.noti-span{
  color: #040404;
  font-family: 'Poppins-medium';
}
/* .coinlogs-ul>li {
  font-size: 16px;
  font-family: 'Poppins-medium';
  line-height: 37px;
  margin: 0px 20px;
  color: black;
  padding: 10px 0;
} */
.coinlogs-ul2 li{
  border-bottom: 1px solid #BBB9B9;
  margin: 0px 20px !important;
  padding: 10px 0 !important;
}
.coinlogs-ul2 li:last-child {
  border-bottom: 0;
}
/* width */
.notifiction-box::-webkit-scrollbar {
  width: 0px;
}
.notifiction-box{
  overflow: auto;
  height: 245px;
}
.footer-link a{
  text-decoration: none !important;
}
.coin-li:hover .icon-color path{
  fill:#39B28E;
}
.header-btn-login2 .MuiButton-containedSizeMedium, .header-btn-login2 .MuiButton-containedSizeMedium:hover {
  text-decoration: none;
  background: transparent linear-gradient(270deg, #87C443 0%, #39B28E 100%) 0% 0% no-repeat padding-box;
  border: 1px #707070 !important;
  min-width: 247px;
  height: 50px !important;
  box-shadow: 0px 3px 6px #0000003b;
  opacity: 1;
  text-transform: capitalize;
  font-size: 18px;
  line-height: 30px;
  font-family: 'Poppins-medium';
  border-radius: 0px !important;
}
.add-copy-link-main{
  display: flex;
    /* width: 100%; */
    align-items: center;
    justify-content: space-between;
}

.email-box2{
  width: 80%!important;
}
.textfield-set2 {
  /* height: 64px; */
  width: 100%;
  max-width: 100% !important;
  background-color: white;
}
.subscribe-btn2 button {
  background: #87C443 0% 0% no-repeat padding-box !important;
  /* border: 1px solid #F9C11478 !important; */
  min-width: 151px !important;
 
}
.email-small-btn2 {
  position: absolute;
  right: 0px !important;
}
.counter-text2 p {
  font-size: 18px;
  font-family: 'Poppins-Regular';
  color: white;
  text-align: center;
  line-height: 25px;
}
 .set-copy-link{
  padding: 65px 0px 0px;
}
.email-box3 {
  width: 45%!important;
}
.email-box4 {
  width: 30%!important;
  margin: 0px 15px;
}
.add-copy-link-main2 {
  display: flex;
  align-items: center;
}
.subscribe-btn3 button {
  background: #87C443 0% 0% no-repeat padding-box !important;
  /* border: 1px solid #F9C11478 !important; */
  min-width: 118px !important;
}
.drop-btn-bnb{
  background: #87C443 0% 0% no-repeat padding-box !important;
  padding: 0px !important;
  margin: 0px !important;
}
.drop-btn-bnb2{
  height: 50px !Important;
  width: 118px !important;
}
 .set-drop-btn2:before {
  /* padding-top: 11px !important; */
  border-bottom: none !important;
 }
 .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input {
  padding-top: 3px !important;
 }
 /* .inpot-box-bnb{
  font-size: 18px !important;
  line-height: 30px !important;
  color: white !important;
    font-family: 'Poppins-medium'!important;
 } */
 .css-67qocj-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:after {
  border-bottom:none !important;
}
.drop-btn-bnb svg path {
  fill: #fff;
}
.drop-btn-bnb .drop-btn-bnb2{
  border-radius:0 !important;
}
.drop-btn-bnb .MuiOutlinedInput-notchedOutline{
  border-width:0 !important;
  color:#fff !important;
}
/* .drop-btn-bnb .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  font-size: 18px !important;
  line-height: 30px !important;
  color: white !important;
    font-family: 'Poppins-medium'!important;
} */
.email-small-btn3 {
  position: absolute;
  right: 0px;
}

.drop-btn-bnb .MuiOutlinedInput-input {
  font-size: 18px !important;
  line-height: 30px !important;
  color: white !important;
  font-family: 'Poppins-medium'!important;
}
.owl-theme-new.owl-theme .owl-dots .owl-dot span {
  width: 20px !important;
  height: 20px !important;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  
  transition: opacity .2s ease;
  border-radius: 30px;
}
.owl-theme-new.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #87c443 !important;
}
.owl-theme-new.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 30px;
}
.set-box{
  text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: '5px';
}

/* start white paper two page css */
.w2-sec {
  padding: 45px 0px 88px;
}

.w2-overview-box {
  border: 1px solid #D9D9D9;
  /* padding: 13px 24px 11px 24px; */
  margin-bottom: 10px;
  padding: 0px 24px 0px 5px;
}

.w2-overview-box .w2-overview-text {
  color: #000000;
  font-family: 'Poppins-SemiBold';
  font-size: 16px;
  line-height: 23px;
}

.w2-acc-main-box .w2-acc {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  box-shadow: none;
  margin: 0px !important;
}

.w2-acc-main-box .w2-acc::before {
  border-color: transparent;
  width: 0px;
}

.w2-acc .w2-acc-summary {
  padding: 0px 24px 0px 24px;
  min-height: 47px !important;
  border: 1px solid #D9D9D9;
  margin-bottom: 10px;
}

.w2-acc .w2-acc-summary .MuiAccordionSummary {
  margin: 0px;
}

.w2-acc .w2-acc-summary .MuiAccordionSummary-content {
  margin: 0px;
}

.w2-acc .w2-acc-expand-icon {
  color: #000000;
}

.w2-acc .w2-acc-title {
  color: #000000;
  font-family: 'Poppins-SemiBold';
  font-size: 16px;
  line-height: 23px;
}

.w2-acc .w2-acc-details {
  padding: 0px;
  box-shadow: 0px 3px 6px #00000030;
  margin-bottom: 10px;
}

.w2-acc .w2-acc-details-title {
  color: #598E13;
  font-family: 'Poppins-SemiBold';
  font-size: 16px;
  line-height: 23px;
  padding: 0px 0px 25px 0px;
}

.w2-acc .w2-acc-details-para {
  color: #598E13;
  font-family: 'Poppins-medium';
  font-size: 16px;
  line-height: 23px;
  padding: 0px 0px 25px 0px;
}

.w2-acc .w2-acc-details-para:last-child {
  padding-bottom: 0px;
}

.w2-technical-content-box {
  margin-top: 22px;
}

.w2-technical-content-box .w2-technical-text {
  color: #22433C;
  font-size: 23px;
  line-height: 28px;
  font-family: 'Poppins-medium';
  margin-bottom: 25px;
}

.wp-tabs-panel .tab-inner{
  color: #000000;
    font-size: 15px;
    line-height: 22px;
    font-family: 'Poppins-Regular';
    margin-bottom: 25px;
    text-align: justify;
}

.wp-tabs-panel .w2-technical-text2{
  color: #22433C;
    font-size: 23px;
    line-height: 28px;
    font-family: 'Poppins-medium';
    margin-bottom: 30px;
}

.w2-technical-content-box .w2-technical-para {
  font-size: 15px;
  line-height: 22px;
  font-family: 'Poppins-Regular';
  color: #000000;
  margin-bottom: 20px;
  width: 100%;
  max-width: 686px;
}

.w2-apna-token-text {
  color: #000000;
  font-family: 'Poppins-SemiBold';
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 19px;
  text-align: center;
}

.w2-apna-token-box {
  max-width: 377px;
  width: 100%;
  position: relative;
}

.w2-token-btns-box .w2-token-btn {
  border: 1px solid #87C443;
  border-radius: 13px;
  max-width: 178px;
  width: 100%;
  color: #39B28E;
  font-size: 13px;
  line-height: 19px;
  padding: 11px 0px 10px 0px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.w2-token-btns-box .w2-token-gap {
  margin-right: 16px;
}

.w2-token-bottom-squre {
  border: 1px solid #707070;
  border-radius: 10px;
  max-width: 346px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 160px;
  position: relative;
}

.w2-token-bottom-squre::after {
  content: '';
  position: absolute;
  background-image: url(../images/png/line3.png);
  width: 364px;
  height: 71.86px;
  background-size: contain;
  background-repeat: no-repeat;
  bottom: -32px;
  left: -132px;
}

.w2-token-bottom-squre::before {
  position: absolute;
  content: '';
  border-right: 1px dashed #707070;
  height: 61px;
  margin-right: 27px;
  top: -62px;
}

.w2-apna-token-box:after {
  content: '';
  position: absolute;
  width: 243px;
  border-top: 1px dashed #707070;
  top: 96px;
  right: -259px;
}

.w2-token-squre-inner-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 11px 28px 23px;
}

.w2-token-top-box {
  display: flex;
  justify-content: space-between;
}

.w2-binancecoin-img {
  height: 108px;
  width: 108px;
  object-fit: contain;
}

.w2-token-fill-btns-box {
  margin-left: auto;
  text-align: right;
}

.w2-token-fill-btns-box .w2-fill-btns {
  background: transparent linear-gradient(90deg, #39B28E 0%, #87C443 100%);
  border-radius: 13px;
  color: #FFFFFF;
  font-size: 13px;
  line-height: 19px;
  font-family: 'Poppins-medium';
  max-width: 153px;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
}

.w2-token-fill-btns-box .w2-fill-btns:last-child {
  margin-bottom: 0px;
}

.we-big-btn {
  height: 80px !important;
}

.w2-middle-btn-box .w2-middle-btn {
  max-width: 172px;
  background: transparent linear-gradient(90deg, #39B28E 0%, #87C443 100%);
  border-radius: 13px;
  color: #FFFFFF;
  font-size: 13px;
  line-height: 19px;
  font-family: 'Poppins-medium';
  width: 100%;
  text-transform: capitalize;
  height: 40px;
  position: relative;
  z-index: 9;
}

.w2-middle-btn-box {
  margin-top: 156px;
  display: flex;
  width: 100%;
  margin-left: 30px;
}

.w2-middle-btn-box .w2-middle-btn:first-child {
  margin-right: 23px;
}

.w2-white-round {
  height: 86px;
  width: 86px;
  border-radius: 50%;
  box-shadow: 0px 1px 10px #39B28E8F;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  background: white;
  margin-top: -10px;
  margin-left: 52px;
}

.w2-white-round-main {
  position: relative;
  margin-top: 80px;
  justify-content: center;
  display: flex;
  max-width: 377px;
}

.w2-white-round-main::before {
  position: absolute;
  content: '';
  background-image: url(../images/png/line1.png);
  width: 107.93px;
  height: 131px;
  background-size: contain;
  background-repeat: no-repeat;
  top: -110px;
  left: 104px;
}

.w2-white-round-main::after {
  position: absolute;
  content: '';
  background-image: url(../images/png/line2.png);
  width: 107.93px;
  height: 131px;
  background-size: contain;
  background-repeat: no-repeat;
  top: -112px;
  right: 28px;
}

.roadmap-banner-sec {
  background-image: url(../images/png/roadmap-banner.png);
}

.news-banner-sec {
  background-image: url(../images/png/news-banner.png);
}

.w2-apna-cast-logo {
  height: 51px;
  width: 48px;
  object-fit: contain;
}

.w2-bottom-main-box {
  max-width: 824px;
  width: 100%;
  margin-top: -18px;
}

.w2-bottom-box:first-child {
  margin-right: 105px;
}

.w2-bottom-small-squre {
  max-width: 177px;
  width: 100%;
  border: 1px solid #707070;
  height: 122px;
  border-radius: 13px;
  margin-left: auto;
}

.w2-bottom-squre-btn-box .w2-bottom-squre-btn {
  min-width: 152px;
  background: transparent linear-gradient(90deg, #39B28E 0%, #87C443 100%);
  border-radius: 13px;
  color: #FFFFFF;
  font-size: 13px;
  line-height: 19px;
  font-family: 'Poppins-medium';
  width: 100%;
  text-transform: capitalize;
  height: 40px;
}

.w2-bottom-squre-btn-box {
  padding: 21px 12px 39px 13px;
}

.w2-bottome-squre-text {
  color: #000000;
  font-family: 'Poppins-Regular';
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  margin: 5px 0px;
}

.w2-bottom-inner-main {
  display: flex;
  justify-content: flex-end;
}

.e2-arrow-up-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 35px 0px 33px;
}

.e2-updown-arrow-img {
  width: 15.48px;
  height: 36px;
  object-fit: contain;
}

.w2-arrow-left-right {
  position: relative;
}

.w2-arrow-left-right::before {
  position: absolute;
  content: '';
  background-image: url(../images/png/line4.png);
  width: 233.33px;
  height: 41.25px;
  background-size: contain;
  background-repeat: no-repeat;
  left: -148px;
  top: -34%;
}

.w2-arrow-left-right::after {
  position: absolute;
  content: '';
  background-image: url(../images/png/line5.png);
  width: 275px;
  height: 59.11px;
  background-size: contain;
  background-repeat: no-repeat;
  top: -58px;
  right: -196px;
}

.w2-token-bottom-content {
  margin: 43px 0px 0px;
}

.w2-token-bottom-title {
  color: #000000;
  font-size: 24px;
  line-height: 33px;
  font-family: 'Poppins-SemiBold';
  margin-bottom: 62px;
  text-align: center;
}

.w2-token-bottom-para {
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  font-family: 'Poppins-Regular';
}

.w2-banner-sec {
  background-image: url(../images/png/whitepapertwo-banner.png);
}

.wp-tabs-panel .css-19kzrtu {
  padding: 0px 20px 0px 28px;
}

.css-9l3uo3 {
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.wp-tabs-main .wp-tabs {
  align-items: flex-start;
  color: #000000;
  font-size: 16px;
  line-height: 23px;
  font-family: 'Poppins-medium';
  text-transform: capitalize;
  max-width: 255px;
  width: 100%;
  min-width: 247px;
  display: flex;
  min-height: 47px;
  text-align: left;
}

.wp-tabs-main .wp-tabs .Mui-selected {
  color: #598E13;
}

.wp-tabs .MuiTabs-indicator {
  background-color: transparent;
  height: 0px;
}

.w2-acc-summary.Mui-expanded .w2-acc-title {
  color: #598E13;
  font-family: 'Poppins-SemiBold';
}

.web-content-box .web-subtitle {
  color: #22433C;
  font-size: 23px;
  line-height: 28px;
  font-family: 'Poppins-medium';
  margin-bottom: 30px;
}

.wp-tabs-panel-main {
  margin-top: 20px;
}

.web-content-box .web-sub-para {
  color: #000000;
  font-size: 15px;
  line-height: 22px;
  font-family: 'Poppins-Regular';
  margin-bottom: 25px;
}
.wp-tabs .MuiTabScrollButton-vertical {
  display: none;
}



.team-sec {
  padding: 0px 0px 0px;
}

.team-card {
  box-shadow: 5px 3px 10px #191e1145;
  background-color: transparent;
  border-radius: 0px;
}

.team-img-box {
  position: relative;
  display: flex;
  flex-direction: column;
}

.team-img {
  height: 261px;
  object-fit: cover;
  width: 100%;
  /* min-width: 278px; */
  max-width: 278px;
  object-position: top;
}

.team-card .team-content-box {
  padding: 35px 30px;
  box-shadow: none;
  border-radius: 0px;
}

.team-container .team-grid {
  padding: 0px;
  background-color: transparent;
}

.team-container .team-grid .grid-item {
  padding: 0px;
  border-radius: 0px;
  box-shadow: none;
  background-color: transparent;
}

.team-container .team-grid:nth-child(odd) .grid-item .team-card .team-content-box {
  background-color: #39B28F;
  color: #fff;
  height: 100%;
  min-height: 150px;
  margin: 0px 0px 15px 0px;
}
.team-container .team-grid:nth-child(odd) .grid-item .team-card .team-content-box .team-inner-name{
  color: #94CA4C;

}
.team-container .team-grid:nth-child(even) .grid-item .team-card .team-content-box .team-inner-name{
  color: #39B28F;

}
.team-container .team-grid:nth-child(even) .grid-item .team-card .team-content-box {
  background-color: #94CA4C;
  color: #fff;
  height: 100%;
  min-height: 150px;
}

.team-content-box .team-inner-title {
  font-size: 21px;
  line-height: 30px;
  font-family: 'Poppins-medium';
  color: #FFFFFF;
  margin-bottom: 14px;
  text-align: left;
}

.team-content-box .team-inner-para {
  font-size: 15px;
  color: #FFFFFF;
  line-height: 22px;
  font-family: 'Poppins-Regular';
  margin-bottom: 14px;
  text-align: left;
}

.team-content-box .team-inner-name {
  font-size: 18px;
  line-height: 25px;
  font-family: 'Poppins-medium';
  margin-bottom: 2px;
  text-align: left;
}

.team-content-box .team-inner-position {
  font-size: 12px;
  color: #504747;
  line-height: 17px;
  font-family: 'Poppins-Regular';
  text-align: left;
}
.outline-image {
  height: 45px;
  width: 45px;
  object-fit: contain;
}
/* end team section */

/* start roadmap page */
.roadmap-sec {
  padding: 96px 0px 61px;
}

.roadmap-main-box .roadmap-ul {
  padding: 0px;
  position: relative;
}

.roadmap-ul .roadmap-li {
  padding: 0px;
  align-items: flex-start;
}

.roadmap-content-main {
  display: flex;
  width: 100%;
  margin-bottom: 45px;
}

.roadmap-left-main {
  max-width: 175px;
  width: 100%;
  flex: 0 0 175px;
}

.roadmap-left {
  background-color: #70bf59;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.roadmap-left .roadmap-date {
  font-size: 16px;
  line-height: 29px;
  color: #FFFFFF;
  font-family: "Poppins-SemiBold";
}

.roadmap-right-main {
  flex: 1;
}

.roadmap-right {
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  margin-left: 23px;
  padding: 17px 14.33px 22px;
}

.body-bg {
  background-color: #F2F5F4;
}

.roadmap-right .roadmap-sub-title {
  color: #000000;
  font-size: 18px;
  line-height: 25px;
  font-family: "Poppins-SemiBold";
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.primaryclick-img {
  margin-right: 10.03px;
  height: 16.64px;
  width: 16.64px;
  margin-top: 5px;
  object-fit: contain;
}

.roadmap-right .roadmap-para {
  font-size: 16px;
  line-height: 23px;
  color: #000000;
  font-family: "Poppins-Regular";
  max-width: 788px;
  width: 100%;
}

.solrazr-img {
  height: 24px;
  width: 82px;
  object-fit: contain;
}

.solarz-top-img {
  margin: 10px 0px 0px;
}

.rodamap-right-data-box {
  display: flex;
  align-items: baseline;
}

.roadmap-ul .roadmap-li.active .roadmap-content-main .roadmap-left-main .roadmap-left {
  background-color: #39B28F;
}

.roadmap-main-box .roadmap-ul::after {
  position: absolute;
  content: '';
  height: 100%;
  background-color: #DEDEDE;
  width: 5px;
  left: 84px;
  right: 0px;
  top: 0px;
}

.roadmap-right-data-box {
  display: flex;
}

.span-live-text {
  font-size: 9px;
  margin-top: -3px;
}

.solana-logo-img {
  height: 30px;
  width: 27px;
  object-fit: cover;
}

.solana-logo-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 180px;
  width: 100%;
  margin-top: 10px;
}

.quanta-img-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 530px;
  width: 100%;
  flex-wrap: wrap;
}

.quanta-img {
  margin-right: 20px;
  height: 84px;
  width: 184px;
  object-fit: contain;
}

.flag-img {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.flags-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 350px;
  width: 100%;
  flex-wrap: wrap;
}

.flag-img-5 {
  height: 50px;
  width: 70px;
  object-fit: contain;
}

.roadmap-ul .roadmap-li:last-child .roadmap-content-main {
  margin-bottom: 0px;
}
/* end roadmap page */

.set-web .MuiFormControl-fullWidth {
  width: 50%;
  padding: 5px;
 }
 .set-web{
   width: 700px;
 }
 .set-web-btn{
   font-size: 13px !important;
 }

.save-btn-box1{
  text-align: center !important;
}
.intro-title-box .intro-title {
  color: #000;
  font-family: Poppins-SemiBold;
  font-size: 45px;
  line-height: 63px;
  margin-bottom: 25px;
}
.intro-para-box .intro-para {
  color: #000;
  font-family: Poppins-Regular;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 25px;
  max-width: 825px;
  width: 100%;
}
.intro-banner-img-grid {
  height: 140.48px;
  width: 100%;
  object-fit: contain;
}
.textbox-label label{
  padding: 5px !important;
}
.swal2-popup {
  width: 24em !important;
}
.swal2-html-container {
  font-size: 15px !important;
}


/* .signup-text{
  display: flex;
  justify-content: center;
  text-align: center;
}
.forgot-text{
  display: flex;
  justify-content: center;
} */
.set-policy-box{
  margin-left: 30px;
}
.footer-link2{
  display: flex;
}
/* .client-about-img2{
  height: 156px;
    width: 156px;
    object-fit: cover;
    border-radius: 50%;
    background-color: white;
    margin: auto;
} */
.client-about-img2 {
  height: 156px;
  width: 156px;
  object-fit: cover;
  border-radius: 50%;
  background-color: white;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.email-box3 {
  width: 547.99px!important;
}
.email-btn-set3 {
  height: 40px !important;
}
.subscribe-btn3 button {
  background: #87C443 0% 0% no-repeat padding-box !important;
   /* border: 1px solid #bdbdbd !important;  */
  min-width: 140px !important;
}
.email-box3 .MuiInputBase-colorPrimary {
  border: 1px solid #bdbdbd !important;
  border-radius: 0px !important;
}
.validation .MuiFormHelperText-sizeMedium {
  margin-left: 0px !important;
}
.remember-box2 {
  margin-bottom: 7px !important;
}
.register-error2 {
  text-align: left !important;
  padding: 0px 30px 10px !important;
  color: rgb(211, 47, 47);
}

.contact-msg-error{
  text-align: left !important;
  color: rgb(211, 47, 47);
}

.login-error {
  text-align: left !important;
  color: rgb(211, 47, 47);
}

.mlr-20 {
    margin: 0px 20px;
}
.hero-set-box{
  padding: 20px 0px 0px;
}
.owl-theme-new.owl-theme2 .owl-dots .owl-dot span {
  width: 20px !important;
  height: 20px !important;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  
  transition: opacity .2s ease;
  border-radius: 30px;
}
.owl-theme-new.owl-theme2.owl-theme .owl-dots .owl-dot.active span, .owl-theme2.owl-theme .owl-dots .owl-dot:hover span {
  background: #87c443 !important;
}
.owl-theme-new.owl-theme2.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 20px;
}
.owl-theme-new.owl-theme2.owl-theme .owl-dots .owl-dot span {
  width: 20px !important;
  height: 20px !important;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  transition: opacity .2s ease;
  border-radius: 30px;
}